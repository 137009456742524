export default  [
    {
        title:'微码管理',
        icon:'el-icon-s-grid',
        child:[
            {
                title:'企微码列表',
                route:'qrcodeList'
            },
            {
                title:'创建企微码',
                route:'qrcodeCreate'
            },
        ]
    },
    {
        title:'客户管理',
        icon:'el-icon-s-custom',
        child:[
            {
                title:'客户列表',
                route:'customerList'
            },
            {
                title:'标签列表',
                route:'customerTagList'
            }
        ]
    },
    {
        title:'员工管理',
        icon:'el-icon-message-solid',
        child:[
            {
                title:'员工列表',
                route:'staffList'
            }
        ]
    },
    {
        title:'消息管理',
        icon:'el-icon-s-comment',
        child:[
            {
                title:'消息列表',
                route:'messageList'
            }
        ]
    },
    {
        title:'企业管理',
        icon:'el-icon-s-tools',
        child:[
            {
                title:'企微配置',
                route:'wechatConfig'
            }
        ]
    },

]