<template>

    <el-aside width="200px" >
      <el-menu  :default-openeds="[menuDefaultIndex.group]" :default-active="menuDefaultIndex.group+'-'+menuDefaultIndex.row" >
        <el-submenu   v-for="(menu,index) in menus" :key="menu.title" :index="`${index+1}`">
          <template slot="title" ><i :class="menu.icon"></i>{{ menu.title }}</template>
          <el-menu-item @click="menuSelect(item)" v-for="(item,idx) in menu.child" :key="item.title" :index="`${index+1}-${idx+1}`" >{{item.title}}</el-menu-item>

        </el-submenu>

      </el-menu>
    </el-aside>


</template>

<script>


import MenuConfig from "@/untils/menuConfig";

export default {
  name: "Side",
  props: {
    msg: String,
  },
  data: function () {
    return {
      menus:MenuConfig,
      menuDefaultIndex: {group:'1',row:'1'}
    };
  },

  mounted() {
    // console.log(this.$router)
    let routName = this.$router.history.current.name
    this.menus.forEach((item,index)=>{
      item.child.forEach((it,idx)=>{
        if(it.route === routName){
          // console.log('***',routName,it.route,index,idx)
         this.menuDefaultIndex = {
            group:`${index+1}`,row:`${idx+1}`
         }

        }
      })
    })


  },
  methods:{
    menuSelect(item){
      this.$router.push({
        name:item.route
      }).catch(()=>{})
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-aside {
  color: #333;
}
.el-menu{
  border: 0;
}


</style>