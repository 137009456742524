<template>
      <router-view></router-view>
</template>

<script>


export default {
  name: 'App',
  components: {

  },
  data(){
    return {
      title:"企业微信"
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 去除选中效果 */


</style>
