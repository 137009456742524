<template>


  <el-main style="background-color: rgb(238, 241, 246); ">


    <el-table
        v-loading="loading"
        :row-style="{height:'45px'}"
        style="font-size: 11px; border: #ddd 1px solid"
        ref="table"
        highlight-current-row
        border
        :header-cell-style="{ textAlign: 'center',backgroundColor: 'rgb(20,50,100)' , color:'white'}"
        :cell-style="{ textAlign: 'center' }"
        :data="tableData"
        :height="tableHeight">
      <el-table-column type="index" label="序号" max-width="50">
      </el-table-column>


      <el-table-column prop="userid" show-overflow-tooltip label="员工ID" max-width="150">
      </el-table-column>
      <el-table-column prop="name" show-overflow-tooltip label="名称" max-width="200">
      </el-table-column>

    </el-table>

  </el-main>

</template>

<script>

import fetch from '../untils/request'
// import VueQr from 'vue-qr'
import { STAFF_LIST } from "../../config.js"

export default {
  name: "QrCodeList",
  props: {
    msg: String,
  },
  data() {
    return {
      tableData: [],
      loading: true,
      tableHeight: 200,

    };
  },

  methods: {



    updateDataSource(){
      let that = this
      that.loading = true
      fetch(STAFF_LIST).then(res => {
        that.tableData = res.data.data
        that.loading = false
      }).catch(() => {
        that.tableData = []
        that.loading = false
      })
    }

  },

  mounted() {

    let that = this

    this.$nextTick(() => {
      that.tableHeight = window.innerHeight - that.$refs.table.$el.offsetTop - 50;
    })

    window.onresize = function () {
      that.tableHeight = window.innerHeight - that.$refs.table.$el.offsetTop - 50;
    }
    this.updateDataSource()



  },
  beforeDestroy() {
    window.onresize = null
  }

};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
