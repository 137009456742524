<template>
  <div>
    <WPHead :title="title"></WPHead>
    <el-container>
      <WPSide/>
      <router-view></router-view>
    </el-container>
  </div>
</template>

<script>


import WPHead from './components/layout/WPHead.vue'
import WPSide from './components/layout/WPSide.vue'

export default {
  name: 'Home',
  components: {
    WPHead,
    WPSide
  },
  data(){
    return {
      title:"企业微信"
    }
  }
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  -webkit-user-select: none;/*禁用手机浏览器的用户选择功能 */
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select:none; /*IE10*/
  user-select:none;

}

</style>
