<template>


  <el-main style="background-color: rgb(238, 241, 246); ">


    <el-table
        v-loading="loading"
        :row-style="{height:'45px'}"
        style="font-size: 11px; border: #ddd 1px solid"
        ref="table"
        highlight-current-row
        border
        :header-cell-style="{ textAlign: 'center',backgroundColor: 'rgb(20,50,100)' , color:'white'}"
        :cell-style="{ textAlign: 'center' }"
        :data="tableData"
        :height="tableHeight">
      <el-table-column type="index" label="序号" max-width="50">
      </el-table-column>
      <el-table-column prop="config_id" show-overflow-tooltip label="配置ID" max-width="150">
      </el-table-column>
      <el-table-column show-overflow-tooltip label="客服员工" max-width="200">
        <template v-slot="scope">
          <span style=" font-size: 11px">{{ scope.row.user.join(' , ') }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="类型" max-width="50">
        <template v-slot="scope">
          <span>{{ scope.row.type === 2 ? "多人" : "单人" }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="场景" max-width="60">
        <template v-slot="scope">
          <span>{{ scope.row.type === 2 ? "二维码" : "小程序" }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="是否验证" max-width="90">
        <template v-slot="scope">
          <span>{{ scope.row.skip_verify ? "否" : "是" }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="二维码" min-width="240">
        <template v-slot="scope" style="text-align: center">
          <el-popover trigger="hover" placement="bottom">
            <!--                <vue-qr  :text="scope.row.qr_code" :size="100"></vue-qr>-->
            <el-image
                style="width: 150px; height: 150px; "
                :src="scope.row.qr_code "
            ></el-image>
            <div slot="reference">
              <span>{{ scope.row.qr_code }}</span>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="state" show-overflow-tooltip label="参数" max-width="150">
      </el-table-column>
      <el-table-column prop="remark" show-overflow-tooltip label="备注" max-width="200">
      </el-table-column>
      <el-table-column fixed="right" label="操作" min-width="100">
        <template v-slot="scope">
          <el-button
              size="mini"
              type="text"
              @click="handleEdit(scope)">编辑
          </el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
        title="编辑企微码信息"
        :before-close="handleDrawerClose"
        :visible="isDrawerShow"
        size="65%"
        ref="drawer">
     <QrCodeCreate :origin-qr-code="drawerData" :handleUpdateQrCodeComplete="handleUpdateQrCodeComplete"/>
    </el-drawer>
  </el-main>

</template>

<script>

import fetch from "../untils/request"
// import VueQr from 'vue-qr'
import { QRCODE_DELETE,QRCODE_LIST } from "../../config.js"
import QrCodeCreate from "@/pages/QrCodeCreate";
export default {
  name: "QrCodeList",
  props: {
    msg: String,
  },
  data() {
    return {
      tableData: [],
      loading: true,
      tableHeight: 200,
      isDrawerShow: false,
      drawerData: {},
    };
  },
  components: {
    // VueQr
    QrCodeCreate,

  },
  methods: {
    handleEdit(scope) {
      this.drawerData = {...scope.row}
      this.isDrawerShow = true
    },
    handleDrawerClose() {
      this.isDrawerShow = false
    },
    handleDelete(scope) {
      let that = this
      let {$index:index, row:{config_id}} = scope

      this.$alert(`确定要删除该条数据？`, '警告', {
        confirmButtonText: '确定',
        callback: (action) => {
          if(action === 'confirm'){
            that.loading = true
            console.log('删除数据')
            fetch(QRCODE_DELETE, {config_id}).then(() => {
              that.tableData.splice(index,1)
              that.loading = false
            }).catch(() => {
              that.loading = false
              this.$message.error('删除失败');
            })
          }
        },
        }
      );
    },
    handleUpdateQrCodeComplete(value){
      this.isDrawerShow = false
      this.tableData = this.tableData.map((item)=>{
        if(item.config_id === value.config_id) {
          item = {
            ...item,
            user:value.user,
            state:value.state,
            remark:value.remark,
            skip_verify:value.skip_verify,
          }

        }
        return item

      })

    },
    updateDataSource(){
      let that = this
      that.loading = true
      fetch(QRCODE_LIST).then(res => {
        that.tableData = res.data.data
        that.loading = false
      }).catch(() => {
        that.tableData = []
        that.loading = false
      })
    }

  },

  mounted() {

    let that = this

    this.$nextTick(() => {
      that.tableHeight = window.innerHeight - that.$refs.table.$el.offsetTop - 50;
    })

    window.onresize = function () {
      that.tableHeight = window.innerHeight - that.$refs.table.$el.offsetTop - 50;
    }
    this.updateDataSource()



  },
  beforeDestroy() {
    window.onresize = null
  }

};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
