import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui';
import VueRouter from 'vue-router'
import router from "@/router";
import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css'

Vue.use(VueRouter)
Vue.use(Element, { size: 'small', zIndex: 3000 });

Vue.config.productionTip = false

let VM = new Vue({
  render: h => h(App),
  router
})
VM.$mount('#app')

export default VM

