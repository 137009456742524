import VueRouter from "vue-router";
import StaffList from "@/pages/StaffList";
import QrCodeList from "@/pages/QrCodeList";
import QrCodeCreate from "@/pages/QrCodeCreate";
import CustomerList from "@/pages/CustomerList";
import CustomerTagList from "@/pages/CustomerTagList";
import MessageList from "@/pages/MessageList";
import WechatConfig from "@/pages/WechatConfig";
import Login from "@/Login";
import Home from '@/Home'


 const  router = new VueRouter({

    mode:"history",
     routes:[
         {
             name:"App",
             path:"/",
             meta:{
                 title:'沃朴物联'
             },
             redirect: { name: 'login' },
         },
         {
             name:"login",
             path:"/login",
             meta:{
                 title:'登录'
             },
             component: Login
         },
         {
             path:"/home",
             meta:{
                 title:'主页',
             },
             component: Home,
             children:[
                 {
                     name:"main",
                     path:"",
                     meta:{
                         title:'主页',
                     },
                     redirect: { name: 'qrcodeList' },
                 },
                 {
                     name:"staffList",
                     path:"staff/staffList",
                     meta:{
                         title:'员工列表',
                     },
                     component:StaffList
                 },
                 {
                     name:"qrcodeList",
                     path:"qrcode/qrcodeList",
                     meta:{
                         title:'企微码列表'
                     },
                     component:QrCodeList
                 },
                 {
                     name:"qrcodeCreate",
                     path:"qrcode/qrcodeCreate",
                     meta:{
                         title:'创建企微码'
                     },
                     component:QrCodeCreate
                 },
                 {
                     name:"customerList",
                     path:"customer/customerList",
                     meta:{
                         title:'客户列表'
                     },
                     component:CustomerList
                 },
                 {
                     name:"customerTagList",
                     path:"customer/customerTagList",
                     meta:{
                         title:'标签列表'
                     },
                     component:CustomerTagList
                 },
                 {
                     name:"messageList",
                     path:"message/messageList",
                     meta:{
                         title:'消息列表'
                     },
                     component:MessageList
                 },
                 {
                     name:"wechatConfig",
                     path:"config/wechatConfig",
                     meta:{
                         title:'企微配置'
                     },
                     component:WechatConfig
                 }

             ]
         }

     ]
})

// 路由前置处理
router.beforeEach((to, from, next)=>{
    // console.log('******************', to.meta.needCheck, from)
    next()

})


// 路由后置处理

router.afterEach((to)=>{
    // console.log('******************',to.meta,from)
    let { title } = to.meta
    document.title = title || '沃朴物联'

})

export default router