<template>

  <el-main style="background-color: rgb(238, 241, 246);">

      <el-form  size="small" v-loading = loading :model="qrcodeData" :rules="rules" ref="qrCodeFrom"  :style="{height:formHeight+'px'}" label-width="100px" class="qrcode_form">

        <el-tag  style="margin:20px; font-size: 16px" size='medium' type="success">{{qrcodeData.config_id?`企微码ID : ${qrcodeData.config_id}`:"新建企微码"}}</el-tag>

        <el-form-item label="员工指配:" prop="staffs" class="form_item">
          <el-transfer
              v-loading = staffLoading
              :titles="['未选列表', '已选列表']"
              filterable
              :filter-method="handleStaffFilter"
              filter-placeholder="请输入员工姓名"
              v-model="qrcodeData.user"
              :data="staffList">
          </el-transfer>
        </el-form-item>

        <el-form-item label="微码参数:" prop="state" class="form_item">
          <el-input v-model="qrcodeData.state"></el-input>
        </el-form-item>

        <el-form-item label="创建标签:"  class="form_item">
          <el-switch v-model="qrcodeData.createCustomerTag"></el-switch>
          <span style="margin-left: 8px; color: gray"> 同步创建『 微码参数 』同名客户标签 </span>
        </el-form-item>

        <el-form-item label="跳过验证:"  class="form_item">
          <el-switch v-model="qrcodeData.skip_verify"></el-switch>
          <span style="margin-left: 8px; color: gray"> 客户『 添加企微客服 』跳过二次验证 </span>
        </el-form-item>

        <el-form-item label="微码备注:" prop="remark" class="form_item">
          <el-input type="textarea" v-model="qrcodeData.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :disabled="validated"  @click="handleSubmitQrCode()">保存</el-button>
          <el-button @click="handleResetQrCode()">重置</el-button>
        </el-form-item>

      </el-form>

  </el-main>

</template>

<script>
import fetch from "../untils/request"

import {STAFF_LIST,QRCODE_CREATE,QRCODE_UPDATE} from "../../config.js"


// import VueQr from 'vue-qr'
export default {
  name: "QrCodeCreate",
  props: {
    originQrCode: Object,
    handleUpdateQrCodeComplete: Function
  },
  data() {
    let dataTemp = {
      staffList: [],
      staffLoading: true,
      loading:false,
      formHeight: 200,
      qrcodeData: {
        skip_verify:true,
        createCustomerTag:true,
        remark:'',
        state:'',
        user:[]
      }
    }

    let validateTransfer = (rule, value, callback) => {
      if (this.qrcodeData.user.length < 1) {
        callback(new Error('请至少指派一名客服员工'));
      } else {
        callback();
      }
    };


    return {
      ...dataTemp,
      rules: {
        staffs: [
          { validator: validateTransfer, trigger: 'change', required: true, }
        ],
        state: [
          { message: '请编辑企微码参数', trigger: 'blur' },
          { min: 0, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        remark: [
          { message: '请添加备注', trigger: 'blur' },
          { min: 0, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ]
      }
    };
  },
  watch: {
    originQrCode: {
      // do something
      handler(value){
        this.qrcodeData = {
          skip_verify:true,
          createCustomerTag:true,
          remark:'',
          state:'',
          user:[],
          ...value
        }
      },
      immediate:true,
      deep:true

    }
  },
  computed:{
    validated(){
      // return true
      return !(this.qrcodeData.user.length > 0 && this.qrcodeData.state.length < 30 && this.qrcodeData.remark.length < 30)
    }
  },
  methods: {

    handleSubmitQrCode(){
      let that = this
      let { user:staffs ,remark, state, config_id , createCustomerTag} = that.qrcodeData

      let params = {
        config_id,
        staffs,
        createCustomerTag,
        remark,
        state
      }
      that.loading = true
      fetch(  that.qrcodeData.config_id?QRCODE_UPDATE:QRCODE_CREATE  ,params).then((res) => {
        that.loading = false
        if (res.data.code === 200){
          that.$message({
            showClose: true,
            message: that.qrcodeData.config_id?'恭喜，保存成功':'恭喜，创建成功，您可以继续创建新码',
            type: 'success'
          });
          that.qrcodeData.config_id ? ( that.handleUpdateQrCodeComplete(that.qrcodeData) ) :that.handleResetQrCode()
        }else {
          that.$message({
            showClose: true,
            message: `${res.data.message}`,
            type: 'error'
          });
        }
      }).catch(() => {
        that.$message({
          showClose: true,
          message: '操作失败，请检查相关配置',
          type: 'error'
        });
        that.loading = false
      })
    },
    handleResetQrCode(){
      this.qrcodeData = this.qrcodeData.config_id ?
          {
            remark: '',
            state: '',
            createCustomerTag:true,
            ...this.originQrCode
          }
          :{
            skip_verify:true,
            createCustomerTag:true,
            remark:'',
            state:'',
            user:[]
          }
    },
    handleStaffFilter(query,item){
      return item.label.indexOf(query) > -1;
    }

  },


  mounted() {

    let that = this

    this.$nextTick(() => {
      that.formHeight = window.innerHeight - that.$refs.qrCodeFrom.$el.offsetTop - 50;
    })

    window.onresize = function () {
      that.formHeight = window.innerHeight - that.$refs.qrCodeFrom.$el.offsetTop - 50;
    }

    fetch(STAFF_LIST).then(res => {

      that.staffList  = res.data.data.map(item=>{
        return {
          key:item.userid,
          label:item.name,
          disabled:false
        }
      })
      that.staffLoading = false
    }).catch(() => {
      that.staffList = []
      that.staffLoading = false
    })

  },
  beforeDestroy() {
    window.onresize = null
  }

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.qrcode_form{
  background-color: white;
  height: 700px;
  /*margin: 10px 40px 10px 10px;*/
  font-size: 11px; border: #ddd 1px solid;
  padding-right: 30px;

}
.form_item{
  margin-top: 25px;
}

</style>
