<template>
  <div class="content" :style="{height:contentHeight+'px'}">
    <div class="login"    v-loading="loading">
      <div class="left">
        <div class="heading"><span>登录</span></div>
        <div class="inputs">
          <div class="user">
            <i class="el-icon-user"></i>
            <input autocomplete="on" v-model="username" type="text" name="username" id="user" placeholder="账号">
          </div>
          <div class="pwd">
            <i class="el-icon-key"></i>
            <input autocomplete="on" v-model="password" type="password" name="password" id="pwd" placeholder="密码" @keyup.enter="submit">
          </div>
        </div>
        <div class="remind"><span>忘记密码</span></div>
      </div>
      <div class="right">
        <div class="regist">
          <span >注册</span>
        </div>
        <a class="action" @click="submit">
          提交
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import fetch from '../../wp_wechat_manage/src/untils/request'

// import VueQr from 'vue-qr'
import { LOGIN } from "../../wp_wechat_manage/config.js"
export default {
  name: "Login",

     data: function () {

    return {
      username: "",
      password: "",
      loading:false
    };
  },
  computed: {
    contentHeight() {
      // return true
      return window.innerHeight
    }
  },
  methods:{
    submit(){
      let that = this

      if(that.username && that.password){
        this.loading = true
        fetch(LOGIN,{
          username:that.username,
          password:that.password
        }).then(res => {
          that.loading = false
          if(res.data.success){
            that.$message({
              showClose: true,
              message: '登录成功',
              type: 'success'
            });
            that.$router.replace({
              name:'qrcodeList'
            }).catch(()=>{})

          }else {
            that.$message({
              showClose: true,
              message: '账号或密码不匹配',
              type: 'error'
            });
          }
        }).catch(() => {
          that.loading = false
          that.$message({
            showClose: true,
            message: '网络异常，请检查网络',
            type: 'error'
          });
        })


      }else{
        that.$alert('账号密码不能为空')
      }

    }
  }


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.content {
  background-image: linear-gradient(to bottom right, rgb(114,135,254) , rgb(130,88,186));
  overflow: hidden;
}

.login {
  width: 40%;
  min-width: 300px;
  max-width: 600px;
  /* height: 315px; */
  margin: 20% auto 0;
  border-radius: 15px;
  box-shadow: 0 10px 50px 0 rgb(59, 45, 159);
  background-color: rgb(95, 76, 194);
}

.login .left {
  width: 70%;
  display: inline-block;
  border-radius: 15px 0 0 15px;
  padding: 60px 10%;
  background-image: linear-gradient(to bottom right, rgb(118, 76, 163), rgb(92, 103, 211));

}

.login .left .heading {
  color: #fff;
  font-size: 18px;
  font-weight: 200;
}

.login .left .heading span {
  border-bottom: 3px solid rgb(237, 221, 22);
}

.login .left .inputs {
  padding: 20px 0;
}

.login .left .inputs {
  color: rgb(199, 191, 219);
}

.user i, .pwd i {

  font-size: 18px;
  /* margin:20px 8px 0; */
  line-height: 18px;
  width: 26px;
  vertical-align: middle;
  /* background-color: aqua; */
}

.login .left .inputs input {
  /* height: 18px; */
  vertical-align: middle;
  border: 0;
  background: none;
  outline: 0;
  line-height: 18px;
  font-size: 15px;
  color: #fff;
  margin: 20px 0;
  /* display: inline-block; */
  width: 80%;
  padding: 5px 0;
  transition: 0.2s;
  border-bottom: 1px solid rgb(199, 191, 219);
  background-color: transparent;

}

.login .left .inputs input:hover {
  border-bottom-color: #fff;
}

::-webkit-input-placeholder {
  color: rgb(199, 191, 219);
  font-size: 13px;
}


.login .left .remind {
  font-size: 14px;
  transition: .2s;
  color: rgb(199, 191, 219);
  cursor: pointer;
}


.login .left .remind:hover {
  color: #fff;
}

.login .right {
  box-sizing: border-box;
  width: 30%;
  float: right;
  /* height: 100%; */
  /* background-color: aquamarine; */
  padding: 60px 0;
  /* text-align: center; */
  /* height: calc(100% - 120px); */
  /* vertical-align: top; */
  /* padding: 0px 0; */
  /* background-image: linear-gradient(to bottom right , rgb(118,76,163) ,rgb(92,103,211)); */
}


.login .right .regist {
  text-align: center;
  color: #fff;
  transition: .2s;
  font-size: 18px;
  font-weight: 200;
  cursor: pointer;
}

.login .right .regist:hover {
  color: rgb(141, 151, 219);
}

.login .right .regist span {
  border-bottom: 3px solid rgb(237, 221, 22);
}

.login .right .action {
  display: block;
  font-size: 13px;
  color: #fff;
  height: 50px;
  width: 50px;
  border: 1px solid rgb(237, 221, 22);
  border-radius: 25px;
  text-align: center;
  line-height: 50px;
  margin: 120px auto;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  /* overflow: hidden; */
}

.login .right .action:active {
  background-color: rgb(237, 221, 22);
  color: rgb(95, 76, 194);

}

/* body .login .right .action:hover {
    background-color: rgb(237,221,22);
    color: rgb(95, 76 ,194);
}  */


/* input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
} */
/*去除谷歌浏览器下自动补全时背景色修改的问题*/
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  caret-color: #fff !important; /* 光标颜色修改 */
  -webkit-text-fill-color: #FFF !important;
  transition: background-color 50000s ease-in-out 0s !important;
}

/*去除safari浏览器下 input 右方图标问题*/
input:focus::-webkit-contacts-auto-fill-button {
  opacity: 0;
}

input::-webkit-credentials-auto-fill-button {
  display: none !important;
  visibility: hidden;
  pointer-events: none;
  position: absolute; /* 避免占用 input 元素额外的 padding，正常情况下存在 display: none!; 就可以了 */
  right: 0;
}


</style>
