//const HOST = 'http://192.168.199.44:3000'

// const HOST = 'http://localhost:9002'

const HOST = 'https://wechat.drowey.com'

export const QRCODE_LIST = `${HOST}/contact/getContactQRCodeList`

export const STAFF_LIST = `${HOST}/staff/getStaffDetailList`

export const QRCODE_CREATE = `${HOST}/contact/createContactQRCode`

export const QRCODE_UPDATE = `${HOST}/contact/updateContactQRCode`

export const QRCODE_DELETE = `${HOST}/contact/deleteContactQRCode`

export const CUSTOMER_LIST = `${HOST}/customer/getAllCustomerDetailList`

export const CUSTOMER_TAG_LIST = `${HOST}/customer/getCustomerTagList`

export const MESSAGE_LIST = `${HOST}/message/getMessageList`

export const LOGIN = `${HOST}/login`


//  export default {
//     QRCODE_LIST,
//      STAFF_LIST
// }