<template>

  <el-main style="background-color: rgb(238, 241, 246);">

    <el-form  size="small"  :model="wechatCompanyData" :rules="rules" ref="qrCodeFrom"  :style="{height:formHeight+'px'}" label-width="100px" class="qrcode_form">

      <el-tag  style="margin:20px; font-size: 16px" size='medium' type="success">公司企微配置</el-tag>


      <el-form-item label="公司名称:" prop="name" class="form_item">
        <el-input v-model="wechatCompanyData.company"></el-input>
      </el-form-item>

      <el-form-item label="应用名称:" prop="app" class="form_item">
        <el-input v-model="wechatCompanyData.app"></el-input>
      </el-form-item>

      <el-form-item label="应用KEY:" prop="key" class="form_item">
        <el-input v-model="wechatCompanyData.key"></el-input>
      </el-form-item>

      <el-form-item label="应用密钥:" prop="secret" class="form_item">
        <el-input v-model="wechatCompanyData.secret"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" disabled  @click="handleSubmitWechatCompanyData()">保存</el-button>
        <el-button @click="handleResetWechatCompanyData()">重置</el-button>
      </el-form-item>

    </el-form>

  </el-main>

</template>

<script>






export default {
  name: "WechatConfig",

  data() {
    let dataTemp = {
      formHeight: 200,
      wechatCompanyData: {
        company:"杭州沃朴物联科技有限公司",
        app:'沃朴物联',
        key:'',
        secret:''
      }
    }




    return {
      ...dataTemp,
      rules: {
        name: [
          { message: '请编辑公司 「 名称 」', trigger: 'blur' },
          { min: 0, max: 30, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        app: [
          { message: '请编辑第三方应用 「 名称 」 ', trigger: 'blur' },
          { min: 0, max: 30, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        key: [
          { message: '请编辑第三方应用 「 Key 」', trigger: 'blur' ,required: true},
          { min: 0, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ],
        secret: [
          { message: '请编辑第三方应用 「 Secret 」', trigger: 'blur',required: true },
          { min: 0, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
        ]
      }
    };
  },

  computed:{

  },
  methods: {

    handleSubmitWechatCompanyData() {
        console.log('测试')
    },

    handleResetWechatCompanyData(){
      this.wechatCompanyData =
          {
            company:"杭州沃朴物联科技有限公司",
            app:'沃朴物联',
            key:'',
            secret:''
          }

    }

  },


  mounted() {

    let that = this

    this.$nextTick(() => {
      that.formHeight = window.innerHeight - that.$refs.qrCodeFrom.$el.offsetTop - 50;
    })

    window.onresize = function () {
      that.formHeight = window.innerHeight - that.$refs.qrCodeFrom.$el.offsetTop - 50;
    }



  },
  beforeDestroy() {
    window.onresize = null
  }

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.qrcode_form{
  background-color: white;
  height: 700px;
  /*margin: 10px 40px 10px 10px;*/
  font-size: 11px; border: #ddd 1px solid;
  padding-right: 30px;

}
.form_item{
  margin-top: 25px;
}

</style>
