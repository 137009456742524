import axios from "axios"

axios.defaults.withCredentials = true

import VM from '../main'

export default function (url, params={}) {
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(res => {
            let result = res.data
            if (result.status === 999) {
                VM.$router.replace({
                    name: 'login'
                }).catch(() => {})
                reject(new Error('无权限访问'))
            } else {
                resolve(res)
            }
        }).catch(err => {
            reject(err)
        })
    })
}