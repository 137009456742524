<template>

  <el-main style="background-color: rgb(238, 241, 246);">
    <el-table
        v-loading="loading"
        :row-style="{height:'30px'}"
        :span-method="objectSpanMethod"
        style="font-size: 11px; border: #ddd 1px solid"
        ref="table"
        border
        :header-cell-style="{ textAlign: 'center',backgroundColor: 'rgb(20,50,100)' , color:'white'}"
        :cell-style="{ textAlign: 'center'}"
        :data="tableData"
        :height="tableHeight">
      <el-table-column type="index" label="序号" max-width="50">
      </el-table-column>

      <el-table-column prop="group_name" show-overflow-tooltip label="标签分组" max-width="150">
      </el-table-column>
      <el-table-column prop="name" show-overflow-tooltip label="标签名称" max-width="150">
      </el-table-column>
      <el-table-column show-overflow-tooltip label="客户数量" max-width="50">
        <template v-slot="scope">
          <span>{{ scope.row.customers.length }}</span>
        </template>
      </el-table-column>

      <el-table-column show-overflow-tooltip label="创建时间" max-width="50">
        <template v-slot="scope">
          <span>{{ new Date(scope.row.create_time * 1000).toLocaleString() }}</span>
        </template>
      </el-table-column>


      <el-table-column fixed="right" label="客户明细" max-width="50">
        <template v-slot="scope">
          <el-button
              :disabled="!scope.row.customers.length"
              size="mini"
              type="text"
              @click="handleCustomerDetail(scope)">查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
        :title="drawerData.name"
        :before-close="handleDrawerClose"
        :visible="isDrawerShow"
        size="65%"
        ref="drawer">
      <el-table
          v-loading="loading"
          :row-style="{height:'30px'}"
          style="font-size: 11px; border: #ddd 1px solid"
          highlight-current-row
          border
          :header-cell-style="{ textAlign: 'center',backgroundColor: 'rgb(20,50,100)' , color:'white'}"
          :cell-style="{ textAlign: 'center'}"
          :data="drawerData.customers"
          :height="tableHeight">
        <el-table-column type="index" label="序号" max-width="50">
        </el-table-column>
        <el-table-column prop="unionid" show-overflow-tooltip label="UnionID" max-width="150">
        </el-table-column>
        <el-table-column prop="name" show-overflow-tooltip label="微信昵称" max-width="100">
        </el-table-column>
        <el-table-column show-overflow-tooltip label="微信头像" min-width="40">
          <template v-slot="scope">
            <el-image
                style="width: 36px; height: 36px; "
                :src="scope.row.avatar"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="性别" max-width="50">
          <template v-slot="scope">
            <span style=" font-size: 11px">{{
                scope.row.gender === 1 ? '男' : (scope.row.gender === 2 ? '女' : '未知')
              }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="类型" max-width="50">
          <template v-slot="scope">
            <span>{{ scope.row.type === 1 ? "普通微信" : "企业微信" }}</span>
          </template>
        </el-table-column>

        <el-table-column show-overflow-tooltip label="客服员工" max-width="200">
          <template v-slot="scope">
            <span style=" font-size: 11px">{{ scope.row.staffs.map(item => item.userid).join(' , ') }}</span>
          </template>
        </el-table-column>

      </el-table>


    </el-drawer>

  </el-main>

</template>

<script>

import fetch from "../untils/request"

import {CUSTOMER_TAG_LIST} from "../../config.js"

export default {
  name: "CustomerTagList",
  props: {
    msg: String,
  },
  data() {
    return {
      tableData: [],
      loading: true,
      tableHeight: 200,
      isDrawerShow: false,
      drawerData: {},
    };
  },
  components: {},
  methods: {
    handleCustomerDetail(scope) {
      this.drawerData = {...scope.row}
      this.isDrawerShow = true
    },
    handleDrawerClose() {
      this.isDrawerShow = false
    },

    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    objectSpanMethod({row, columnIndex}) {
      if (columnIndex === 1) {
        if (row.groupIndex === 0) {
          return {
            rowspan: row.groupTagCount,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }

    },


    updateDataSource() {
      let that = this

      fetch(CUSTOMER_TAG_LIST).then(res => {
        let tagInfoList = []
        res.data.data.forEach(group => {
          group.tag.forEach((tag, index) => {
            tag.group_name = group.group_name
            tag.groupIndex = index
            tag.groupTagCount = group.tag.length
            tagInfoList.push(tag)
          })
        })
        that.tableData = tagInfoList
        that.loading = false
      }).catch(() => {
        that.tableData = []
        that.loading = false
      })
    }

  },

  mounted() {

    let that = this

    this.$nextTick(() => {
      that.tableHeight = window.innerHeight - that.$refs.table.$el.offsetTop - 50;
    })

    window.onresize = function () {
      that.tableHeight = window.innerHeight - that.$refs.table.$el.offsetTop - 50;
    }
    this.updateDataSource()


  },
  beforeDestroy() {
    window.onresize = null
  }

};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
