<template>
<el-header>
  <el-row type="flex" justify="space-between">
    <el-col style="text-align: left;" :span="8"> 
       <el-image
        style="width: 200px; height: 15px;"
        :src="require('../../../../wp_wechat_manage/src/assets/logo.png')"
        >
        </el-image>
    </el-col>
    <el-col style="text-align: center;" :span="8"><span style="font-size:17px">{{title}}</span></el-col>
    <el-col style="text-align: right; margin-left:15px" :span="8">
      <el-dropdown>
          <i class="el-icon-setting" style="margin-right: 15px; color:white "></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>查看</el-dropdown-item>
            <el-dropdown-item>新增</el-dropdown-item>
            <el-dropdown-item>删除</el-dropdown-item>
          </el-dropdown-menu>
      </el-dropdown>
      <span>沃朴物联</span>
    </el-col>
  </el-row>
</el-header>     
</template>

<script>
export default {
  name: "Head",
  props: {
    title: String,
  },
  data(){
    return {
      
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .el-row{
  background-color: #33be;
  color: #fff;
  line-height: 60px;
  font-size: 15px;
  height: 60px;
  position: relative;
} */
.el-header {
  background-color: #143264;
  color: #fff;
  line-height: 60px;
  height: 100px;
  font-size: 15px
}
</style>